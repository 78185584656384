import { Button, Typography } from 'antd';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import EmptyLayout from '../components/layout/EmptyLayout';
import SearchEngineOptimization from '../components/utility/seo';

const { Text, Title } = Typography;

const SignUpPending = () => {
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('orderId');

  return (
    <EmptyLayout>
      <SearchEngineOptimization title="sign up pending" />
      <div className="flex flex-col items-center">
        <StaticImage
          src="../images/circle-check.png"
          alt="x-circle"
          placeholder="tracedSVG"
          className="w-[48px] h-[48px]"
          objectFit="contain"
        />
        <Title className="!font-semibold mt-5 mb-4 !text-light-title" level={3}>
          ขอบคุณสร้างบัญชีกับเรา
        </Title>
        <Text className="!text-light-primary text-center">
          บัญชีของคุณได้ถูกสร้างเรียบร้อยแล้ว
          กรุณารอการยืนยันจากทางผู้ดูแลระบบเพื่อเริ่มใช้งาน
        </Text>
        <Link to={`/`}>
          <Button type="primary" className="mt-6">
            กลับสู่หน้าแรก
          </Button>
        </Link>
      </div>
    </EmptyLayout>
  );
};

export default SignUpPending;
